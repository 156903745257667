'use client';
import Link from 'next/link';
import Image from 'next/image';
import CaretRight from '@/icons/caret-right.svg';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/navigation';
import { createNewInvoiceOrder } from '@/utils/checkoutUtils';
import { useShoppingCart } from '@/context/shoppingCartContext';
import { useUserContext } from '@/context/UserContext';
import { GtagItem, sendEcommerceEvent } from '@/modules/googleAnalytics';
import { Order, ValidatedDiscountCode } from '@/modules/apiTypes';
import { calculateTotalCost } from '@/utils/cartUtils';
import { getCategoryTree } from '@/utils/productUtils';
import { fbqTrackPurchase } from '@/modules/metaPixel';
import { StrapiCheckoutSectionData } from '@/modules/strapiTypes';
import CheckoutCart from './CheckoutCart';
import { CheckoutForm, FormValues } from './CheckoutForm';
import KlarnaCheckout from './KlarnaCheckout';
import OrderCreationFailureModal from './OrderCreationFailureModal';
import { DiscountCodeField } from './DiscountCodeField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

export function CheckoutSection(data: Readonly<StrapiCheckoutSectionData>) {
  const router = useRouter();
  const [formValues, setFormValues] = useState<FormValues>();
  const [finalizedOrder, setFinalizedOrder] = useState<Order>();
  const [
    displayOrderCreationFailureModal,
    setDisplayOrderCreationFailureModal,
  ] = useState<boolean>(false);
  const {
    shoppingCart,
    displayVat,
    selectedShippingAlternative,
    shoppingCartProductAmount,
    ...shoppingCartUtils
  } = useShoppingCart();
  const { selectedCustomer, authToken } = useUserContext();
  const { categoryMap } = useShoppingCart();
  const [discountCode, setDiscountCode] = useState<
    ValidatedDiscountCode | undefined
  >(undefined);
  const [useDiscountCode, setUseDiscountCode] = useState<boolean>(false);

  const handleFormSubmit = (values: FormValues) => {
    setFormValues(values);

    if (values.paymentOption === 'invoice') {
      createNewInvoiceOrder(
        shoppingCart.content,
        values,
        handleFinalizedOrder,
        authToken,
        selectedCustomer,
        setDisplayOrderCreationFailureModal,
        displayVat,
        selectedShippingAlternative?.id,
        discountCode
      );
    }
  };

  const [analyticsSent, setAnalyticsSent] = useState<boolean>(false);
  useEffect(() => {
    if (
      shoppingCartProductAmount &&
      shoppingCartProductAmount.length > 0 &&
      !analyticsSent
    ) {
      const gtagItemList: GtagItem[] = shoppingCartProductAmount.map(
        (productAmount) => {
          const categories = getCategoryTree(productAmount, categoryMap);
          return {
            item_id: productAmount.product.id.toString(),
            item_name: productAmount.product.name,
            price: Number(productAmount.product.custom_price_excl_vat ?? 0),
            quantity: productAmount.amount,
            item_category: categories.at(-1),
            item_category2: categories.at(-2),
            item_category3: categories.at(-3),
            item_category4: categories.at(-4),
            item_category5: categories.at(-5),
          };
        }
      );
      sendEcommerceEvent({
        name: 'begin_checkout',
        value: calculateTotalCost(
          false,
          shoppingCartProductAmount,
          discountCode
        ).totalDiscountedCost,
        currency: 'SEK',
        items: gtagItemList,
      });
      setAnalyticsSent(true);
    }
  }, [
    analyticsSent,
    displayVat,
    shoppingCart,
    shoppingCartProductAmount,
    categoryMap,
    discountCode,
  ]);

  const handleFinalizedOrder = (order: Order) => {
    if (shoppingCartProductAmount && shoppingCartProductAmount.length > 0) {
      const gtagItemList: GtagItem[] = shoppingCartProductAmount.map(
        (productAmount) => {
          const categories = getCategoryTree(productAmount, categoryMap);
          return {
            item_id: productAmount.product.id.toString(),
            item_name: productAmount.product.name,
            price: Number(productAmount.product.custom_price_excl_vat ?? 0),
            quantity: productAmount.amount,
            item_category: categories.at(-1),
            item_category2: categories.at(-2),
            item_category3: categories.at(-3),
            item_category4: categories.at(-4),
            item_category5: categories.at(-5),
          };
        }
      );

      const { totalDiscountedCost } = calculateTotalCost(
        false,
        shoppingCartProductAmount,
        discountCode
      );

      sendEcommerceEvent(
        {
          name: 'purchase',
          value: totalDiscountedCost,
          currency: 'SEK',
          transaction_id: order.id,
          shipping: Number(
            selectedShippingAlternative?.product.custom_price_excl_vat ?? 0
          ),
          tax:
            calculateTotalCost(true, shoppingCartProductAmount, discountCode)
              .totalDiscountedCost -
            calculateTotalCost(false, shoppingCartProductAmount, discountCode)
              .totalDiscountedCost,
          items: gtagItemList,
          purchase_type: formValues?.companyCheckout ? 'company' : 'private',
        },
        formValues?.billingInfo
      );

      fbqTrackPurchase(
        shoppingCartProductAmount.map(({ product }) => product.id),
        totalDiscountedCost
      );
    }

    setFinalizedOrder(order);
    void router.push('/butik/orderbekraftelse?order_id=' + order.id);
    shoppingCartUtils.emptyShoppingCart();
  };

  return (
    <div className="px-default-sm md:px-default">
      <div className="mx-auto mb-20 mt-10 max-w-page-width-mx-680">
        <div className="flex">
          <p className="mr-2 text-lg font-semibold hover:underline">
            <Link href={`/butik`}>{data.breadcrumb_store ?? 'Butik'}</Link>
          </p>
          <Image
            className="mr-2"
            src={CaretRight as string}
            alt="CaretRight"
            height="24"
            width="24"
          />
          <div className="mr-2 text-lg text-gray-500 hover:underline">
            {finalizedOrder ? (
              <p>{data.breadcrumb_finalized_order}</p>
            ) : (
              <Link href="/butik/kassa">{data.breadcrumb_checkout}</Link>
            )}
          </div>
        </div>
        <CheckoutCart data={data} discountCode={discountCode} />
        <div className="mb-8">
          <button
            onClick={() => setUseDiscountCode(!useDiscountCode)}
            disabled={discountCode !== undefined}
            className={discountCode !== undefined ? 'cursor-not-allowed' : ''}
          >
            Använd rabattkod{' '}
            <FontAwesomeIcon
              icon={useDiscountCode ? faChevronUp : faChevronDown}
            />
          </button>
          {useDiscountCode && (
            <DiscountCodeField
              setValidDiscountCode={(data): void => {
                setDiscountCode(data);
              }}
            />
          )}
        </div>
        <CheckoutForm
          data={data}
          handleFormSubmit={handleFormSubmit}
          stateFormValues={formValues}
          discountCode={discountCode}
        />
        {formValues?.paymentOption === 'klarna' && (
          <KlarnaCheckout
            data={data}
            formValues={formValues}
            handleFinalizedOrder={handleFinalizedOrder}
            setDisplayOrderCreationFailureModal={
              setDisplayOrderCreationFailureModal
            }
            discountCode={discountCode}
          />
        )}
      </div>
      {displayOrderCreationFailureModal && (
        <OrderCreationFailureModal
          data={data}
          setDisplayOrderCreationFailureModal={
            setDisplayOrderCreationFailureModal
          }
        />
      )}
    </div>
  );
}
