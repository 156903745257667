import { useEffect, useRef, useState } from 'react';
import { getOrderHistory } from '@/modules/apiClient';
import Image from 'next/image';
import DropdownArrowIcon from '@/icons/dropdown-menu-arrow-black.svg';
import Link from 'next/link';
import { useUserContext } from '@/context/UserContext';
import dayjs from 'dayjs';
import FormattedPrice from '@/components/general/FormattedPrice';
import { FullOrder, ProductAmount } from '@/modules/apiTypes';
import { productUrl } from '@/modules/helper';
import { ProductPrice } from '@/components/pageStore/ProductPrice';
import { StrapiProfileSectionData } from '@/modules/strapiTypes';

export default function OrderHistory(data: StrapiProfileSectionData) {
  const { authToken } = useUserContext();
  const [orderHistory, setOrderHistory] = useState<FullOrder[] | undefined>(
    undefined
  );

  useEffect(() => {
    void (async () => {
      const orderHist = await getOrderHistory(authToken);
      setOrderHistory(orderHist);
    })();
  }, [authToken]);

  return (
    <div className="py-default-sm md:py-default">
      {orderHistory && orderHistory.length > 0 ? (
        orderHistory.map((order) => (
          <OrderLine
            key={order.order.created_at}
            shoppingCartOrder={order}
            data={data}
          />
        ))
      ) : (
        <h3 className="py-10 text-center">{data.order_history_empty_text}</h3>
      )}
    </div>
  );
}

interface OrderLineProps {
  shoppingCartOrder: FullOrder;
  data: StrapiProfileSectionData;
}

function OrderLine({ shoppingCartOrder, data }: OrderLineProps) {
  const [showContent, setShowContent] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  return (
    <div className="w-full rounded bg-white drop-shadow-lg">
      <div
        className="group flex cursor-pointer flex-wrap justify-between gap-2 px-10 py-6"
        onClick={() => setShowContent(!showContent)}
      >
        <h3 className="underline-offset-4 group-hover:underline">
          {dayjs(shoppingCartOrder.order.created_at).format('YYYY/MM/DD')}
        </h3>
        <div className="inline-flex gap-2">
          <h3>
            <FormattedPrice price={shoppingCartOrder.order.total_cost ?? 0} />
          </h3>
          <Image
            className={`transition-transform duration-300 ${
              showContent ? 'rotate-180' : ''
            }`}
            src={DropdownArrowIcon as string}
            alt={'Dropdown Arrow Icon'}
          />
        </div>
      </div>
      <div
        className="divide-y divide-custom-grey overflow-hidden px-10 pb-2 text-sm transition-all duration-300 md:text-base"
        style={{
          maxHeight: showContent ? contentRef.current?.scrollHeight ?? 0 : 0,
        }}
        ref={contentRef}
      >
        <div className="py-4">
          {shoppingCartOrder.products.map((productAmount) => (
            <ProductLine key={productAmount.product.name} {...productAmount} />
          ))}
        </div>
        <div className="py-4">
          <Addresses fullOrder={shoppingCartOrder} data={data} />
        </div>
        <div className="flex flex-wrap gap-2 py-4">
          <div className="flex flex-wrap justify-center text-2xl font-light md:mb-2.5 md:mt-auto md:inline-flex md:justify-start">
            <div className="mb-1 h-auto self-end font-light">
              {data.order_history_total_price_text}&nbsp;
            </div>
            <div className="text-3xl font-bold">
              <FormattedPrice price={shoppingCartOrder.order.total_cost ?? 0} />
            </div>
            <p className="mb-1 h-auto self-end font-light ">
              &nbsp;{data.order_history_vat_included_text}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function ProductLine(productAmount: ProductAmount) {
  return (
    <div className="my-3 flex w-full flex-col justify-between pt-3 md:my-2 md:flex-row md:pt-0">
      <div className="flex flex-row">
        <div className="relative h-20 w-20 shrink-0 md:w-28">
          {productAmount.product.images.length > 0 && (
            <Image
              className="rounded border object-cover"
              src={productAmount.product.images[0].url}
              alt={productAmount.product.images[0].alt}
              fill
            />
          )}
        </div>
        <div className="h-min px-6 md:py-2">
          <Link
            href={productUrl(productAmount.product)}
            className="font-raleway font-semibold hover:underline md:text-lg"
          >
            {productAmount.product.name}
          </Link>
        </div>
      </div>

      <div className="my-auto mr-8 flex flex-row justify-between md:space-x-8">
        <div className="custom-item-price mt-auto text-lg md:my-auto">
          <ProductPrice product={productAmount.product} />
        </div>
        <div className="mt-auto inline-flex rounded-full border border-hlr-gunmetal text-lg md:my-auto">
          <input
            value={productAmount.amount}
            disabled={true}
            className="w-10 rounded-full bg-white p-1 text-center md:w-12"
          />
        </div>
      </div>
    </div>
  );
}

interface AddressesProps {
  fullOrder: FullOrder;
  data: StrapiProfileSectionData;
}

function Addresses({ fullOrder, data }: AddressesProps) {
  const order = fullOrder.order;
  const customer = fullOrder.customer;
  return (
    <div className="flex flex-wrap gap-4">
      <div className="grow">
        <div className="my-2 font-bold">
          {data.order_history_billing_address_text}
        </div>
        <div>
          {order.recipient.first_name} {order.recipient.last_name}
        </div>
        <div>{order.recipient.company_name}</div>
        <div>{order.recipient.address.address}</div>
        <div>{order.recipient.address.address2}</div>
        <div>
          {order.recipient.address.zip_code} {order.recipient.address.city}
        </div>
        <div>{order.recipient.address.country}</div>
      </div>
      {customer && (
        <div className="grow">
          <div className="my-2 font-bold">
            {data.order_history_delivery_address_text}
          </div>
          {customer.customer_type == 'company' && (
            <div>{customer.company_name}</div>
          )}
          <div>
            {customer.first_name} {customer.last_name}
          </div>
          <div>{customer.address}</div>
          <div>{customer.address2}</div>
          <div>
            {customer.zip_code} {customer.city}
          </div>
          <div>{customer.country}</div>
        </div>
      )}
    </div>
  );
}
